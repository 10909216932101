<template>
  <div>
    <table class="table table-striped table-hover">
      <tbody>
        <tr>
          <th class="d-flex justify-content-center"></th>
          <th>
            Router Serial
          </th>
        </tr>
        <tr v-for="router in routers" :key="router.id">
          <td class="d-flex justify-content-center">
            <input
            v-model="selectedRouter"
              :value="router.id"
              type="radio"
              class="form-check-input"
            />
          </td>
          <td>{{ router.serial_number }}</td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-center py-4">
      <button
        class="btn btn-outline-secondary mr-4"
        type="button"
        data-dismiss="modal"
      >
        Close
      </button>
      <button
        class="btn btn-primary"
        :disabled="selectedRouter.length === 0 || loading"
        @click="assignRouters"
      >
        Assign Selected Router
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "KioskRouterAssignTable",
  data() {
    return {
      router: "",
      routers: "",
      selectedRouter: [],
      responseErrors: {},
      loading: false,
      kiosk: "",
    };
  },
  mounted() {
    this.getRouters();
  },
  methods: {
    assignRouters() {
      this.loading = true;

      const data = {
        router: this.selectedRouter,
      };

      axios
        .patch(`v1/kiosks/${this.$route.params.id}/`, data)
        .then(async (response) => {
          if (response.status === 200) {
            this.$swal("Success", "Router Assigned to kiosk", "success");
            window.location.reload();
          }
        })
        .catch((error) => {
          if (error.response) {
            this.responseErrors = error.response.data;
            this.loading = false;
          }
        });
    },
    getRouters() {
      axios
        .get(`v1/routers/?kiosk=&partner=&location=Delivered`)
        .then((response) => {
          if (response.status === 200) {
            this.routers = response.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },
    handleSelectRouter(selected, id) {
      const currentlySelected = new Set(this.selectedRouter);
      if (selected) {
        currentlySelected.add(id);
        this.selectedRouter = Array.from(currentlySelected);
      } else {
        this.selectedRouter = this.selectedRouter.filter(
          (routerId) => routerId !== id
        );
      }
    },
    handleSelectAllRouters(selected) {
      const currentlySelected = new Set(
        this.routers.map((router) => router.id)
      );
      if (selected) {
        this.selectedRouter = Array.from(currentlySelected);
      } else {
        this.selectedRouter = [];
      }
    },
  },
};
</script>

<style></style>
