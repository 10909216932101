<template>
  <div>
    <div class="container-fluid pb-3 pt-5 pt-md-6">
      <div class="row col-12 page-head">
        <nav aria-label="breadcrumb" role="navigation">
          <ol class="breadcrumb page-head-nav">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'dashboard' }">Home</router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'kiosks' }">Kiosks</router-link>
            </li>
            <li class="breadcrumb-item">Kiosk Details</li>
          </ol>
        </nav>
      </div>
      <div class="row">
        <div class="user-profile col-md-4 mb-3">
          <div class="user-display">
            <div class="user-display-bg">
              <img src="/img/deal.png" alt="Profile Background" />
            </div>
          </div>
        </div>
        <div class="col-md-8 mb-3">
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="scroll">
                <h3 class="text-uppercase mb-3">Kiosk Details</h3>
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <th>Serial Number</th>
                      <td>{{ kiosk.serial_number }}</td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td>
                        <span v-if="kiosk.functional" class="text-secondary"
                          >Functional</span
                        >
                        <span v-else class="text-warning">Damaged</span>
                      </td>
                    </tr>
                    <tr v-if="kiosk.partner">
                      <th>Partner</th>
                      <td>
                        <router-link
                          :to="{
                            name: 'partner-details',
                            params: { id: kiosk.partner.id },
                          }"
                        >
                          {{ kiosk.partner.name }}
                        </router-link>
                      </td>
                    </tr>
                    <tr v-if="kiosk.partner">
                      <th>Date Assigned</th>
                      <td>
                        {{ new Date(kiosk.assigned_at).toLocaleString() }}
                      </td>
                    </tr>
                    <tr>
                      <th>Router</th>
                      <td v-if="kiosk.router">
                        <router-link
                          :to="{
                            name: 'router-details',
                            params: { id: kiosk.router.id },
                          }"
                        >
                          {{ kiosk.router.serial_number }}
                        </router-link>
                      </td>
                      <td v-else>
                        <span>No Router on kiosk.</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Created</th>
                      <td>{{ new Date(kiosk.created).toLocaleString() }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="d-flex justify-content-around py-3">
                <button
                  class="btn btn-primary"
                  data-toggle="modal"
                  data-target="#assign-router-modal"
                  v-html="btnMessage"
                  v-if="loggedInUser.role === adminRole"
                ></button>
                <b-button @click="show = true" class="btn btn-primary ml-2"
                  >Print QR</b-button
                >
              </div>
              <b-modal hide-backdrop v-model="show" title="Print Qr">
                <p>
                  <KioskQrComponent :item="kiosk" />
                </p>
                <template v-slot:modal-footer>
                  <div class="w-100">
                    <b-button
                      variant="success"
                      size="md"
                      class="float-right"
                      @click="show = false"
                    >
                      Close
                    </b-button>
                  </div>
                </template>
              </b-modal>
              <div class="modal fade" id="assign-router-modal">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h3 class="modal-title">Assign Routers</h3>
                    </div>
                    <div class="modal-body">
                      <KioskRouterAssignTable />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import KioskQrComponent from "@/components/Kiosks/QrCode";
import KioskRouterAssignTable from "@/components/Kiosks/AssignRouterTable";
export default {
  components: { KioskQrComponent, KioskRouterAssignTable },
  data() {
    return {
      kiosk: {},
      show: false,
      btnMsg: "Assign Router",
      adminRole: "ared_admin",
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"]),
    btnMessage() {
      if (this.kiosk.router) {
        return `Update Router`;
      }
      return `Assign Router`;
    },
  },
  mounted() {
    this.getKiosk(this.$route.params.id);
  },
  methods: {
    getKiosk(id) {
      axios
        .get(`v1/kiosks/${id}/`)
        .then((response) => {
          if (response.status === 200) {
            this.kiosk = response.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },
  },
};
</script>

<style></style>
