<template>
  <div>
    <div class="row">
      <div id="qr" class="col-md-12 text-center">
        <div>
          <qrcode-vue
            :value="productQrData"
            :size="300"
            level="H"
            :renderAs="'svg'"

          ></qrcode-vue>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12 text-center">
        <b-button class="outline-success" @click="print"
          >Print Qr code</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  name: "KioskQrComponent",
  components: { QrcodeVue },
  props: {
    item: {
      type: Object,
      default() {
        return {
          serial_number: "",
        };
      },
    },
  },
  computed: {
    productQrData() {
      const data = {
        id: this.item.id,
        serial_number: this.item.serial_number,
        type: "kiosk",
      };
      return JSON.stringify(data);
    },
  },
  methods: {
    print() {
      this.$htmlToPaper("qr");
    },
  },
};
</script>
