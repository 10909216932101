var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-fluid pb-3 pt-5 pt-md-6"},[_c('div',{staticClass:"row col-12 page-head"},[_c('nav',{attrs:{"aria-label":"breadcrumb","role":"navigation"}},[_c('ol',{staticClass:"breadcrumb page-head-nav"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'dashboard' }}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'kiosks' }}},[_vm._v("Kiosks")])],1),_c('li',{staticClass:"breadcrumb-item"},[_vm._v("Kiosk Details")])])])]),_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-8 mb-3"},[_c('div',{staticClass:"card shadow-sm"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"scroll"},[_c('h3',{staticClass:"text-uppercase mb-3"},[_vm._v("Kiosk Details")]),_c('table',{staticClass:"table table-striped"},[_c('tbody',[_c('tr',[_c('th',[_vm._v("Serial Number")]),_c('td',[_vm._v(_vm._s(_vm.kiosk.serial_number))])]),_c('tr',[_c('th',[_vm._v("Status")]),_c('td',[(_vm.kiosk.functional)?_c('span',{staticClass:"text-secondary"},[_vm._v("Functional")]):_c('span',{staticClass:"text-warning"},[_vm._v("Damaged")])])]),(_vm.kiosk.partner)?_c('tr',[_c('th',[_vm._v("Partner")]),_c('td',[_c('router-link',{attrs:{"to":{
                          name: 'partner-details',
                          params: { id: _vm.kiosk.partner.id },
                        }}},[_vm._v(" "+_vm._s(_vm.kiosk.partner.name)+" ")])],1)]):_vm._e(),(_vm.kiosk.partner)?_c('tr',[_c('th',[_vm._v("Date Assigned")]),_c('td',[_vm._v(" "+_vm._s(new Date(_vm.kiosk.assigned_at).toLocaleString())+" ")])]):_vm._e(),_c('tr',[_c('th',[_vm._v("Router")]),(_vm.kiosk.router)?_c('td',[_c('router-link',{attrs:{"to":{
                          name: 'router-details',
                          params: { id: _vm.kiosk.router.id },
                        }}},[_vm._v(" "+_vm._s(_vm.kiosk.router.serial_number)+" ")])],1):_c('td',[_c('span',[_vm._v("No Router on kiosk.")])])]),_c('tr',[_c('th',[_vm._v("Created")]),_c('td',[_vm._v(_vm._s(new Date(_vm.kiosk.created).toLocaleString()))])])])])]),_c('div',{staticClass:"d-flex justify-content-around py-3"},[(_vm.loggedInUser.role === _vm.adminRole)?_c('button',{staticClass:"btn btn-primary",attrs:{"data-toggle":"modal","data-target":"#assign-router-modal"},domProps:{"innerHTML":_vm._s(_vm.btnMessage)}}):_vm._e(),_c('b-button',{staticClass:"btn btn-primary ml-2",on:{"click":function($event){_vm.show = true}}},[_vm._v("Print QR")])],1),_c('b-modal',{attrs:{"hide-backdrop":"","title":"Print Qr"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"success","size":"md"},on:{"click":function($event){_vm.show = false}}},[_vm._v(" Close ")])],1)]},proxy:true}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('p',[_c('KioskQrComponent',{attrs:{"item":_vm.kiosk}})],1)]),_c('div',{staticClass:"modal fade",attrs:{"id":"assign-router-modal"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_vm._m(1),_c('div',{staticClass:"modal-body"},[_c('KioskRouterAssignTable')],1)])])])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-profile col-md-4 mb-3"},[_c('div',{staticClass:"user-display"},[_c('div',{staticClass:"user-display-bg"},[_c('img',{attrs:{"src":"/img/deal.png","alt":"Profile Background"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h3',{staticClass:"modal-title"},[_vm._v("Assign Routers")])])
}]

export { render, staticRenderFns }